.filter-display {
  .ant-select-selector {
    background: #fff !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .ant-picker-selector {
    background: #fff !important;
  }
}

.filter-display-panel {
}