.selected-row {
  background: #E6F7FF;
  &:hover > .segment-table-column {
    background: #e6f1ff !important;
  }
}

.segment-table-column {
  padding: 8px !important;
  border-left: none !important;
  border-right: none !important;
}

.segment-table {
  .ant-pagination-item-active {
    border: none !important;
  }
}