.popup-overlay {
  position: fixed !important;
  width: 100% !important;
}
div.tipper-host.charts {
  position: absolute !important;
  margin-left: -208px;
}

data-menu-drill.menu-popup {
  position: absolute !important;
  margin-left: -208px;
}

// TODO temporary, remove later
#filter {
  div {
    height:698px;
  }
}